<template>
  <div class="product-top-left">
    <div class="product-top-left-choose">
      <div class="product-top-left-choose-title">
        Jetzt Zaunhöhe wählen:
      </div>

      <div class="product-selection">
        <div
          class="product-selection__height-unit"
          v-for="option in getRichOptions" :key="option.label"
        >
          <div class="product-selection__scale" />
          <div class="product-selection__height" :class="option.value ? '' :'blank'">
            <a
              v-if="option.value"
              @click="$emit('input', option.value)"
              title="Nach dem Klick auf die gewünschte Zaunhöhe gelangen Sie zu unserem Komplettzaun-Konfigurator"
              role="button"
            >
              <span>{{ option.label }}</span>
            </a>
          </div>
        </div>

        <img
          src="/assets/content/zaun24_grafik-zaunhoehe.png"
          class="product-selection__silhouette"
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true
    }
  },
  computed: {
    getRichOptions () {
      const options = [];
      this.range().forEach((height) => {
        const opts = this.options.filter((option) => {
          const valHeight = parseInt(option.label.match(/\d|\.|-/g).join(''))
          return (valHeight < height && valHeight >= height - 20)
        })
        if (opts.length > 0) {
          opts.reverse().forEach((opt) => {
            options.push(opt);
          })
        } else if (height <= 200) {
          options.push({
            label: height,
            value: null
          })
        }
      });
      return options;
    }
  },
  methods: {
    range () {
      return Array(20).fill(400).map((x, y) => x - y * 20);
    }
  }
}
</script>
